.label {
  margin-top: 16px;

  .box_load {
    margin: 4px 0;
    width: 100%;
    height: 270px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 2px dashed #ECF2F7;
    border-radius: 20px;

    >img {
      width: 33px;
      height: 33px;
    }

    &_drag {
      border: 2px dashed #0090D1;
    }

    &_text {
      margin: 16px 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      text-align: center;
      color: #3E444A;

      p:first-child {
        margin-bottom: 12px;
      }
    }

    &_textName {
      color: #0090D1;
    }

    &_btn {
      box-shadow: none !important;

      span {
        margin-left: 9px;
        font-size: 14px !important;
        text-transform: lowercase;

        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
  }
}

.box_file {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;

  img {
    cursor: pointer;
  }

  div {
    display: flex;
    grid-gap: 17px;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
  }

  span {
    background-color: #F1F5F9;
    padding: 4px 6px;
    border-radius: 8px;
  }
}

.btn_add {
  margin-top: 10px;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #0090D1;
  cursor: pointer;

  >span:first-child {
    font-size: 25px;
  }
}

.box_bottom {
  margin-top: 15px;
  display: flex;
  grid-gap: 10px;
  align-items: center;
  justify-content: space-between;

  >div {
    width: 100%;
    height: 2px;
    background-color: red;
  }

  .text_delete {
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 4px;
    color: red;
    cursor: pointer;
  }
}



.main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  &__first {
    display: flex;
    align-items: center;
  }

  &__image {
    margin-right: 20px;
  }

  &__fileName {
    font-family: Raleway, 'sans-serif';
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
  }

  span {
    background-color: #F1F5F9;
    padding: 4px 6px;
    border-radius: 8px;
  }
}


.box_logo {
  position: relative;
  height: 178px;
  width: 240px;
  border-radius: 12px;

  &_item {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }

  >img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }

  div {
    cursor: pointer;
    position: absolute;
    bottom: 16px;
    right: 16px;
    width: 40px;
    height: 40px;
    border-radius: 30px;
    background: #EDEDED;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}



.box_img {
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  width: 100%;
  background: #FAFAFA;
  border: 1px dashed #EEEEEE;
  border-radius: 12px;
  padding: 12px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.label {
  color: #68717B;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'liga' off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin-bottom: 2px;
}

.text_add {
  margin-top: 4px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 12px !important;
  display: inline-block !important;
  color: #0090D1 !important;
  border-bottom: 1px solid #0090D1 !important;
  cursor: pointer !important;
}


.deleteText {
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 4px;
  font-family: 'Raleway' !important;
  color: red;
  cursor: pointer;
  font-size: 12px;
}

.box_error {
  color: red !important;
  border: 2px dashed red !important;
}