.box {
    position: relative;

    >img {
        width: 100%;
        border-radius: 8px;
    }

    >div {
        position: absolute;
        background: #EDEDED;
        border-radius: 30px;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    &_trash {
        bottom: 8px;
        right: 8px;
    }

    &_edit {
        bottom: 8px;
        right: 50px;
    }
}

.modal_content {
    background: #FFFFFF;
    border-radius: 16px;
    padding: 20px;
    width: 400px;

    label {
        margin-top: 12px;

        input {
            margin-top: 4px;
        }
    }

    .box_top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4 {
            font-size: 17px;
        }

        img {
            cursor: pointer;
        }
    }

    .box_bottom_btn {
        margin-top: 20px;

        button {
            cursor: pointer;
            width: 120px;
            height: 40px;
            border-radius: 12px;

            &:first-child {
                background: #0090D1;
                color: #FFFFFF;
            }

            &:last-child {
                margin-left: 16px;
                color: #0090D1;
                background: #F0F8FF;
            }
        }
    }
}