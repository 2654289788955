.participantsTopContainer {
  max-width: 1300px;
  margin: 24px auto 0 auto;
  display: flex;
  justify-content: space-between;

  @media (max-width: 565px) {
    .participantsTopContainer {
      height: 120vh;
    }
  }

  & .participantsTopLanguageDropDown {
    margin-top: auto;
    margin-bottom: 5px;
  }
}

.visibleFlag {
  margin-right: 8px;
  margin-top: 5px;
  position: relative;
}

;

.visibleLanguage {
  position: relative;
  bottom: 1px;
}

;

.dropDownFlag {
  margin-right: 8px;
  margin-top: 5px;
}

.dropDownLanguage {
  position: relative;
  bottom: -3px;
}


.participantsTop {
  & .participantsTopTitle {
    font-family: 'Raleway', serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
    color: #3E444A;
  }
}

.breadcrumbsLink {
  font-family: 'Raleway', serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #0090D1;
}

.participantsListContainer {
  max-width: 1300px;
  margin: 24px auto 0 auto;
  padding: 0 20px;
  background: #fff;
  border-radius: 24px;

  MuiPopper-root.MuiTooltip-popper.MuiTooltip-popperInteractive.css-yem0l2-MuiPopper-root-MuiTooltip-popper {
    .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementTop.css-1s8zqit-MuiTooltip-tooltip {
      background-color: #0090D1 !important;
    }
  }

  MuiTooltip-tooltip.MuiTooltip-tooltipPlacementTop.css-1s8zqit-MuiTooltip-tooltip {
    background-color: #0090D1 !important;
  }

  MuiTooltip-tooltip.MuiTooltip-tooltipPlacementTop {
    background-color: #0090D1 !important;
  }

  .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementTop {
    background-color: #0090D1 !important;
  }
}

.participantsListTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.participantsListTitle,
.participantsListAmount,
.participantsListSecondTitle {
  color: #3E444A;
  font-family: Raleway, serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 40px;
}

.participantsListAmount,
.participantsListSecondAmount {
  color: #3E444A;
  opacity: 0.5;
  margin-left: 8px;
}

.participantsListSecondTitle,
.participantsListSecondAmount {
  font-size: 14px;
}

.tooltip {
  &MuiPopper-root.MuiTooltip-popper.MuiTooltip-popperInteractive.css-yem0l2-MuiPopper-root-MuiTooltip-popper {
    &MuiTooltip-tooltip.MuiTooltip-tooltipPlacementTop.css-1s8zqit-MuiTooltip-tooltip {
      background: #0090D1 !important;
    }
  }

  &MuiTooltip-tooltip MuiTooltip-tooltipPlacementTop.css-1s8zqit-MuiTooltip-tooltip {
    background: #0090D1 !important;
  }

  background: #0090D1 !important;
}

.cover {
  .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementTop.css-1s8zqit-MuiTooltip-tooltip {
    background: #0090D1 !important;
  }
}

MuiTooltip-tooltip .participantsFilterContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-grow: 1;
  min-width: 0
}

@media (max-width: 712px) {
  .participantsFilterContainer {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
  }
}

@media (max-width: 490px) {
  .participantsFilterContainer {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
  }
}

.corporateSearchInputContainer {
  display: inline-block;
  width: 100%;
  position: relative;

  & .participantInput {
    width: 100%;
    border: 2px solid #C0D4E3;
    padding: 8px 10px 8px 12px;
    background: #fff;
    border-radius: 12px;
    font-size: 14px;
    line-height: 24px;
    font-family: Raleway, serif;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.70);

    cursor: pointer;

    &:focus {
      border-color: #0090D1;
    }

    &::placeholder {
      font-size: 14px;
      line-height: 24px;
      font-family: Raleway, serif;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
    }
  }

  & .participantSearchIcon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
}



.participantInputContainer {
  display: inline-block;
  width: 85%;
  position: relative;

  @media (max-width: 1160px) {
    width: 80%;
    row-gap: 15px;
  }

  @media (max-width: 880px) {
    width: 75%;
  }

  @media (max-width: 712px) {
    width: 100%;
  }

  & .participantInput {
    width: 100%;
    border: 2px solid #C0D4E3;
    padding: 8px 10px 8px 12px;
    background: #fff;
    border-radius: 12px;
    font-size: 14px;
    line-height: 24px;
    font-family: Raleway, serif;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.70);

    cursor: pointer;

    &:focus {
      border-color: #0090D1;
    }

    &::placeholder {
      font-size: 14px;
      line-height: 24px;
      font-family: Raleway, serif;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
    }
  }

  & .participantSearchIcon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  //@media (max-width: 490px) {
  //  & .participantSearchIcon {
  //    display: none;
  //  }
  //}
}

.participantFilterButton {
  min-width: 120px;

  @media (max-width: 712px) {
    margin-top: 20px;
  }
}


// @media (max-width: 600px) {
//   .participantFilterButton {
//     margin-top: 20px;
//   }
// }

.selectTitle {
  font-family: Raleway, serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #68717B;
}

.resetSelectedItem {
  font-family: Raleway, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #0090D1;
  background: transparent;
  cursor: pointer;
}

;

.columnHeader {
  background: #ECF2F7;
  color: #3E444A;
  font-size: 12px;

  &:last-child {
    border-radius: 0 12px 0 0;
  }

  &:first-child {
    border-radius: 12px 0 0 0;
  }
}

.voucher {
  color: #3E444A;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.payment {
  color: #3E444A;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.participantEmail {
  color: #3E444A;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
}

.pwd {
  position: relative;
  top: 10px;
  color: #3E444A;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
}

.noRecords {
  color: #3E444A;
  font-size: 20px;
  font-family: Raleway, 'serif';
  text-transform: none;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
  margin: 15px 0;
}

.pwdImage {
  position: relative;
  top: 12px;
  right: 10px;
  margin-right: auto;
  margin-left: 10px;
}

.countriesAutoComplete {
  width: 250px;
  margin-left: 15px;
}

@media (max-width: 550px) {

  .countriesAutoComplete {
    margin-left: 0;
  }
}

.countriesAutoCompleteTop {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;

  &>span {
    font-family: Raleway, serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #68717B;
  }
}

@media screen and (max-width: 550px) {
  .participantsListTitle {
    font-size: 16px;
    line-height: 15px;
  }

  .excelButtonsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 15px;
  }
}

.resetMuiDefaultStyle {
  background: transparent !important;

  &:hover {
    background: #e3f2fd !important;
  }
}