.nav {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 20px;

  &_line {
    background: #ecf2f7;
    border-radius: 2px;
    height: 4px;
  }

  &_title {
    margin-top: 12px;
    color: black;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding: 0 5px;
  }

  &_text {
    color: black;
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
    max-width: 256px;
    width: 100%;
    padding: 0 5px;
    margin-bottom: 10px;
  }

  &_activeLink {
    h5 {
      color: #0090d1;
    }

    div {
      background-color: #0090d1;
    }
  }
}

.nav_link {
  padding: 5px;
  border: 4px;
  border-radius: 4px;
}

.nav_error {
  background: rgba(255, 0, 0, 0.1);
  border-radius: 7px;
  padding: 5px;
}
