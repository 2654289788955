.subVouchers {
    padding: 15px 30px 0 30px;
    background-color: #fff;
    border-radius: 16px;
    flex-grow: 1;
  
  }

  .subVouchersContainer {
    max-width: 1300px;
    margin: 35px auto 0 auto;
    display: flex;
    flex-direction: column;
    height: 85vh;
  
  }