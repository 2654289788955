.visibleFlag {
  margin-right: 8px;
  margin-top: 5px;
  position: relative;
};

.visibleLanguage {
  position: relative;
  bottom: 3px;
};

.dropDownFlag {
  margin-right: 8px;
  margin-top: 5px;
}

.dropDownLanguage {
  position: relative;
  bottom: -3px;
}
