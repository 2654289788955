.box_bottom {
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: -135px;
  right: -20px;

  button {
    width: 140px;
    height: 48px;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    border-radius: 12px;
    cursor: pointer;
  }

  .btn_back {
    color: #0090D1;
    background: none;
    border: 1px solid #0090D1;
  }

  .btn_next {
    margin-left: 20px;
    color: white;
    background: #0090D1;
  }
}