.toolbar {
  display: flex;
  align-items: center;
  padding: 12px 18%;
  gap: 2.5%;
  position: absolute;
  width: 100%;
  height: 72px;
  left: 0px;
  top: 0px;
  background: #0090D1;
}

.icon {
  width: 37px;
  height: 37px;
}

.icon img {
  width: 100%;
  height: 100%;
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
}

.info {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
}
