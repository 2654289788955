.headerContainer {
  background-color: #fff;
}

.marathonHeader {
  font-weight: 600;
  font-size: 18px;
  color: #3E444A;
  line-height: 24px;
}

.registerLink {
  color: #0090D1;
  position: relative;
  font-weight: 500;
  padding-left: 10px;
  margin-right: 10px;
  margin-left: 10px;
  font-size: 13px;

  &:before {
    content: '';
    position: absolute;
    top: -2px;
    left: -5px;
    width: 1px;
    height: 24px;
    background-color: #C0D4E3;
  }
}

.loginLink {
  line-height: 24px;
  background-color: #0090D1;
  padding: 8px 25px;
  font-weight: 500;
  border-radius: 14px;
  color: #fff;
  margin: 0 5px;
  font-size: 13px;
}

.headerLogo {
  display: flex;
  align-items: center;
}

.userName {
  color: #3E444A;
  font-size: 14px;
  line-height: 20px;
  &:hover {
    cursor: pointer;
  }
}

.adminProfile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 10px;
  background: #F1F5F9;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  left: 10px;

  &:before {
    content: '';
    position: absolute;
    top: 4px;
    left: -17px;
    width: 1px;
    height: 30px;
    background-color: #C0D4E3;
    margin-left: 5px;
  }
}

.userDropDownMenuLink {
  color: #3E444A;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;

  &:hover {
    color: #0090D1;
  }

  &:hover svg path {
    stroke: #0090D1;
  }

  & .userDropDownMenuLabel {
    margin-left: 10px;
  }
}

.userDropDownMenuLinkActive {
  color: #0090D1;
  font-weight: 500;
  font-size: 14px;

  & svg path {
    stroke: #0090D1;
  }

  & .userDropDownMenuLabel {
    margin-left: 10px;
  }
}

@media screen and (max-width: 1200px) {
  .registerLink {
    padding-left: 10px;
    margin-right: 5px;
    margin-left: 10px;
  }

  .loginLink {
    line-height: 24px;
    background-color: #0090D1;
    padding: 5px 23px;
    font-weight: 500;
    border-radius: 13px;
    color: #fff;
    font-size: 12px;
  }
}

@media screen and (max-width: 1100px) {
  .registerLink {
    font-size: 16px;
    &:before {
      content: none;
    }
  }

  .loginLink {
    font-size: 16px;
    padding: 10px 20px;
  }

  .registerLinkNone {
    display: none;
  }

  .loginLinkNone {
    display: none;
  }
}

@media screen and (max-width: 1100px) {
  .loginLink {
    padding: 5px 23px;
  }

  .registerLink {
    margin-right: 5px;
    margin-left: 5px;
  }
}
