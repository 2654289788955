.headerContainer {
  background-color: #fff;
}

.marathonHeader {
  font-weight: 600;
  font-size: 18px;
  color: #3E444A;
  line-height: 24px;
}

.registerLink {
  color: #0090D1;
  position: relative;
  font-weight: 500;
  padding-left: 10px;
  margin-right: 10px;
  margin-left: 10px;
  font-size: 13px;

  &:before {
    content: '';
    position: absolute;
    top: -2px;
    left: -5px;
    width: 1px;
    height: 24px;
    background-color: #C0D4E3;
  }
}

.loginLink {
  line-height: 24px;
  background-color: #0090D1;
  padding: 8px 25px;
  font-weight: 500;
  border-radius: 14px;
  color: #fff;
  margin: 0 5px;
  font-size: 13px;
}

.headerLogo {
  display: flex;
  align-items: center;
}

.userName {
  color: #3E444A;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  &:hover {
    cursor: pointer;
  }
  &:before {
    content: '';
    position: absolute;
    top: -2px;
    left: -12px;
    width: 1px;
    height: 24px;
    background-color: #C0D4E3;
  }
}

@media screen and (max-width: 1200px) {
  .registerLink {
    padding-left: 10px;
    margin-right: 5px;
    margin-left: 10px;
  }

  .loginLink {
    line-height: 24px;
    background-color: #0090D1;
    padding: 5px 23px;
    font-weight: 500;
    border-radius: 13px;
    color: #fff;
    font-size: 12px;
  }
}

@media screen and (max-width: 1100px) {
  .registerLink {
    font-size: 16px;
    &:before {
      content: none;
    }
  }

  .loginLink {
    font-size: 16px;
    padding: 10px 20px;
  }

  .registerLinkNone {
    display: none;
  }

  .loginLinkNone {
    display: none;
  }
}

@media screen and (max-width: 415px) {
  .marathonHeader {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
}

@media screen and (max-width: 380px) {
  .marathonHeader {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
  }
}
