.voucherListTableContainer {
  max-width: 1420px;
  margin: 24px auto 0 auto;
  padding: 0 20px;
  border-radius: 24px;
  font-family: Raleway, sans-serif;
}

.voucherList {
  background: #fff;
  border-radius: 16px;
  padding: 24px 24px 40px 24px;
}

.breadcrumbsLink {
  font-family: 'Raleway', serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #0090D1;
  cursor: pointer;
}

.voucherNameContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  & .voucherName {
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
    color: #3E444A;

    @media screen and (max-width: 650px) {
      font-size: 22px;
    }

    @media screen and (max-width: 450px) {
      font-size: 19px;
    }

    @media screen and (max-width: 400px) {
      font-size: 16px;
    }

  }


}

.voucherListTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  & .voucherListTitleContainer {
    display: flex;
    align-items: center;

    & .voucherListTitle {
      font-weight: 600;
      font-size: 20px;
      line-height: 40px;
      color: #3E444A;
      margin-right: 8px;

      @media screen and (max-width: 450px) {
        font-size: 18px;
      }

      @media screen and (max-width: 425px) {
        font-size: 14px;
      }
    }

    &>span {
      font-weight: 600;
      font-size: 24px;
      line-height: 40px;
      color: #3E444A;
      opacity: 0.5;

      @media screen and (max-width: 450px) {
        font-size: 20px;
      }

      @media screen and (max-width: 400px) {
        font-size: 16px;
      }
    }
  }
}

.voucherListButtonsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.noRecords {
  color: #3E444A;
  font-size: 20px;
  font-family: Raleway, 'serif';
  text-transform: none;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
  margin: 15px 0;
}

.addPromocodesTopModal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  & .modalCloseIcon {
    cursor: pointer;
    padding: 5px;
  }
}

.addPromocodesTopModalTitle {
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #3E444A;
}

.voucherDistanceContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;


  & .chooseDistance {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #68717B;
  }
}

// .voucherSlotsAmount,
// .editVoucherName {

//   &>label {
//     font-family: 'Raleway', sans-serif;
//     font-weight: 500;
//     font-size: 13px;
//     line-height: 18px;
//     color: #68717B;
//   }

//   & .voucherSlotsAmountInput,
//   .editVoucherInput {
//     font-family: 'Raleway', sans-serif;
//     display: block;
//     width: 100%;
//     border: 2px solid #C0D4E3;
//     padding: 10px 32px 10px 12px;
//     border-radius: 12px;
//     margin-top: 7px;
//     font-weight: 400;
//     line-height: 24px;
//     font-size: 14px;
//     color: #3E444A;

//     &:hover {
//       border: 2px solid #0090D1;
//       box-shadow: none;
//     }

//     &:focus {
//       border: 2px solid rgba(0, 144, 209, 1);
//       box-shadow: none;
//     }

//     &::placeholder {
//       font-family: 'Raleway', sans-serif;
//       font-weight: 500;
//       font-size: 14px;
//       color: rgba(0, 0, 0, 0.35);
//     }
//   }
// }

.availableSlots {
  display: inline-block;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #3E444A;
  margin-bottom: 25px;
  margin-top: 7px;
}

.addVoucherModalBottom,
.editVoucherModalBottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.voucherMenuItem {
  &:nth-child(2) img {
    width: 20px;
    height: 20px;
  }

  &:hover img {
    filter: invert(35%) sepia(62%) saturate(1702%) hue-rotate(171deg) brightness(100%) contrast(105%);
  }

  & svg {
    width: 20px;
    height: 20px;
  }

  &:hover svg {
    filter: invert(35%) sepia(62%) saturate(1702%) hue-rotate(171deg) brightness(100%) contrast(105%);
  }
}

.modalCloseIcon {
  cursor: pointer;
}

.editVoucherName {
  width: 100%;
  margin-bottom: 20px;
}

.voucherName {
  font-weight: 600;
  font-size: 20px;
  margin-top: 20px;
  color: #3E444A;
}

.editVoucherText {
  font-family: Raleway, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #68717B;
  margin-bottom: 25px;
}

.selectContainer {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.resetMuiDefaultStyle {
  background: transparent !important;
  overflow-x: auto !important;

  &:hover {
    background: #e3f2fd !important;
  }
}

.selectTopContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;

  @media screen and (max-width: 650px) {

    flex-direction: column;
  }
}

.selectTitle {
  font-family: Raleway, serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #68717B;
}

.resetSelectedItem {
  font-family: Raleway, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #0090D1;
  background: transparent;
  cursor: pointer;

}


.VoucherSearchInputContainer {
  display: inline-block;
  width: 100%;
  position: relative;

  & .voucherSearchInput {
    width: 100%;
    border: 2px solid #C0D4E3;
    padding: 8px 10px 8px 12px;
    background: #fff;
    border-radius: 12px;
    font-size: 14px;
    line-height: 24px;
    font-family: Raleway, serif;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.70);
    cursor: pointer;

    @media screen and (max-width: 475px) {
      padding-right: 14%;
      padding-left: 3%;
    }

    &:focus {
      border-color: #0090D1;
    }

    &::placeholder {
      font-size: 14px;
      line-height: 24px;
      font-family: Raleway, serif;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);

      @media screen and (max-width: 650px) {
        font-size: 12px;
        line-height: 22px;
      }

      @media screen and (max-width: 480px) {
        font-size: 12px;
        line-height: 20px;
      }
    }
  }

  & .voucherSearchIcon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(-50%, -50%);
    cursor: pointer;

    @media screen and (max-width: 650px) {
      right: 0%;
    }

    @media screen and (max-width: 480px) {
      right: -1%;
    }

  }
}

.voucherPromocodeStartNumber {
  display: inline-block;
  width: 80px;
}

.activatedVoucherPromocode {
  display: inline-block;
  width: 250px;
  word-break: break-all;

  span {
    vertical-align: middle;
  }

  @media screen and (max-width: 450px) {
    font-size: 12px;
  }
}

.voucherPromocodeDistance {
  display: inline-block;
  width: 250px;
  word-break: break-all;

  @media screen and (max-width: 450px) {
    font-size: 14px;
  }
}

@media screen and (max-width: 425px) {
  .voucherPopUpFirst {
    position: absolute;
    top: 25%;
    left: 45%;
  }
}

@media screen and (max-width: 400px) {
  .paginationCount {
    font-size: 0.8rem;
  }
}


.arrows {
  display: inline-block;
  padding-left: 8px;
  margin-bottom: -2px;
  cursor: pointer;
}