@import "./styles/index";

.tabshead {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #68717b;
  margin-left: 8px;
  text-transform: none;
  display: flex;
  gap: 5px;
  align-items: center;
}

.label {
  color: #68717b;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "liga" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin-bottom: 2px;
}

.global-card-distance {
  clip-path: polygon(
    58.69% 25%,
    60.92% 31.83%,
    63.15% 25%,
    75% 25%,
    75% 75%,
    63.15% 75%,
    60.92% 68.17%,
    58.69% 75%,
    25% 75%,
    25% 25%
  );
  border: 1px solid red;
  background: red;
  width: 100%;
}

.globalErrorMessage {
  color: #d32f2f;
  font-family: Raleway;
  font-weight: 400;
  font-size: 0.75rem !important;
  line-height: 1.66;
  text-align: left;
  margin-top: 4px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}

.plusIcon {
  fill: red;
}

.plusIcon:hover {
  fill: white;
}
