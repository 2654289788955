.wrapper {
  position: relative;
}

.buttonProgress {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}

@media all and (max-width: 560px){
  .wrapper{
    font-size: 12px;
  }
}

@media all and (max-width: 425px){
  .wrapper{
    font-size: 10px;
  }
}

