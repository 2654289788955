.inputWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  label {
    color: #68717B;
    font-variant-numeric: lining-nums proportional-nums;
    font-feature-settings: 'liga' off;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Raleway;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 2px;
  }

  input {
    overflow: hidden;
    color: #3E444A;
    font-variant-numeric: lining-nums proportional-nums;
    text-overflow: ellipsis;
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

}