.container {
  max-width: 1300px;
  margin: 0 auto;
  font-family: Raleway, sans-serif;
  padding: 0 10px;
}

.participantsMainTitle {
  color: #3E444A;
  font-weight: 600;
  font-size: 20px;
  line-height: 40px;
  margin-top: 30px;
}

.listOfParticipants {
  background: #fff;
  border-radius: 24px;
  padding: 10px 5px;
}

.participantsSearchInputContainer {
  width: 50%;
  position: relative;
  & .participantsSearchInput {
    width: 100%;
    padding: 8px 32px 8px 12px;
    border-radius: 12px;
    border: 2px solid #C0D4E3;
    font-size: 13px;
    line-height: 24px;
    font-weight: 400;
    font-family: Raleway, sans-serif;

    &:focus {
      border-color: #0090D1;
    }
  }

  & .participantsSearchIcon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
  }
}

.noRecords {
  color: #3E444A;
  font-size: 20px;
  font-family: Raleway, 'serif';
  text-transform: none;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
  margin: 15px 0;
}

.startNumberContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.startNumberColumn {
  min-width: 120px;
  width: 120px;
}

@media screen and (max-width: 768px) {
  .participantsSearchInputContainer {
    width: 85%;
  }
}

@media screen and (max-width: 600px) {
  .participantsSearchInputContainer {
    width: 100%;
  }
}
