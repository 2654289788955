.activateModalTopWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .activateModalTitle {
    font-weight: 500;
    font-size: 22.5px;
    line-height: 28px;
    color: #201f1e;
    margin-bottom: 15px;
  }

  & img {
    cursor: pointer;
  }
}

.activateModalButtonWrapper {
  display: flex;
  justify-content: center;
  gap: 40px;
}

.activateModalText {
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #212529;
  padding: 15px;
}
