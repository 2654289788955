.registerLink {
  color: #0090D1;
  position: relative;
  font-weight: 500;
  padding-left: 10px;
  margin-right: 10px;
  margin-left: 10px;
  font-size: 14px;

  &:before {
    content: '';
    position: absolute;
    top: -2px;
    left: -5px;
    width: 1px;
    height: 24px;
    background-color: #C0D4E3;
  }
}

.loginLink {
  line-height: 24px;
  background-color: #0090D1;
  padding: 10px 24px;
  font-weight: 500;
  border-radius: 8px;
  color: #fff;
  margin: 0 15px;
  font-size: 13px;
}

@media screen and (max-width: 1200px) {
  .registerLink {
    padding-left: 10px;
    margin-right: 5px;
    margin-left: 10px;
  }

  .loginLink {
    line-height: 24px;
    background-color: #0090D1;
    padding: 10px 24px;
    font-weight: 500;
    border-radius: 13px;
    color: #fff;
    margin: 0 10px;
    font-size: 12px;
  }
}

@media screen and (max-width: 1100px) {
  .loginLink {
    margin: 0 0 0 5px;
    font-size: 16px;
    padding: 10px 20px;
  }

  .registerLink {
    margin-right: 5px;
    margin-left: 5px;
    font-size: 16px;
    &:before {
      content: none;
    }
  }

  .registerLinkNone {
    display: none;
  }
}
