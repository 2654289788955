.marathonInfoContainer {
  font-family: "Raleway", serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #3e444a;
}

.marathonInfoTop {
  margin-bottom: 30px;
}

.marathonInfoDate {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  & > .marathonInfoDateText {
    margin-left: 10px;
  }

  & > .marathonInfoPrintingDateText {
    margin-left: 30px;
  }
}

.marathonInfoLocation {
  display: flex;
  align-items: center;

  & > img {
    width: 20px;
    height: 20px;
    filter: invert(42%) sepia(100%) saturate(670%) hue-rotate(161deg)
      brightness(81%) contrast(108%);
  }

  & > .marathonInfoLocationText {
    margin-left: 10px;
  }
}

.marathonDescription {
  margin-bottom: 30px;
  & .marathonDescriptionTitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 16px;
  }
}

.marathonDocuments {
  margin-bottom: 30px;

  & .marathonDocumentsDocument {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 350px;
    padding: 20px 0;
    border: 1px solid #eeeeee;
    border-radius: 8px;
    margin-bottom: 16px;
    cursor: pointer;

    & .marathonDocumentsDocumentDescription {
      color: #3e444a;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      cursor: pointer;
    }
    @media (max-width: 378px) {
      width: 290px;
    }
  }
}

.marathonPartners {
  & .marathonPartnersTitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 16px;
  }

  & .marathonPartnersName {
    color: #3e444a;
    font-weight: 500;
    font-size: 14px;
    line-height: 19.6px;
    margin-bottom: 10px;
  }
}

.marathonPartnerCompaniesImgContainer {
  display: inline-block;
  margin: 10px 10px 10px 0;
  border-radius: 16px;

  & .marathonPartnerCompaniesImg {
    width: 130px;
    height: 100px;
    border-radius: 16px;
    border: 4px solid #ecf2f7;
  }
}
