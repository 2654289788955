.modal {
  border-radius: 16px;
  background: #b0eeb7;
  width: 511px;
  padding-top: 64px;

  &_content {
    max-width: 600px;
    position: relative;
    border-radius: 16px;
    background: #fff;
    padding: 32px;
    padding-bottom: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .icon {
      position: absolute;
      top: -30px;
    }

    .title {
      color: var(--Text, #3e444a);
      font-family: Raleway;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }

    .body {
      margin-bottom: 32px;
      color: var(--DarkGrey, #68717b);
      font-variant-numeric: lining-nums proportional-nums;
      font-feature-settings: "liga" off;
      font-family: Raleway;
      font-size: 16px;
      font-weight: 500;
    }

    .btn {
      width: 100%;
      margin-bottom: 16px;
      display: flex;
      height: 40px;
      padding: 8px 24px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 12px;
      background: var(--Primary, #0090d1);
      color: #fff;
      font-family: Raleway;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      /* 171.429% */
      text-transform: none;
    }
  }
}

.modal_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
