.headerListItem {
  display: inline-block;
  margin: 0 15px;
  position: relative;
}

.headerActiveClassName {
  color: #0090D1;
  font-size: 14px;
}

.headerLink {
  color: #68717B;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media screen and (max-width: 1170px) {
  .headerListItem {
    margin: 0 10px;

    &:first-child {
      margin-left: 0;
    }
  }
}