@import 'normalize';

:root {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Raleway', sans-serif;
  background-color: #ECF2F7;
}

body.lock {
  overflow: hidden;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

input,
button,
textarea,
select {
  border: none;
  outline: none;
}

.container {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;
}

.list-breadCrumbs{
  display: flex;
  grid-gap: 15px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  a{
      color: #0090D1;
      >span:last-child{
          margin-left: 15px;
      }
  }
}

//GLobal classes Personal Cabinet and Admin Panel

.container-personalCabinet, .container-adminPanel {
  background-color: #FFFFFF;
  border-radius: 16px;
  padding: 25px 25px 34px;
  width: 100%;
  min-width: 320px;
  position: relative;
}
@media (max-width: 450px) {
  .container-personalCabinet, .container-adminPanel {
    left: -10px;
  }
}
@media (max-width: 350px) {
  .container-personalCabinet, .container-adminPanel {
    left: -15px;
  }
  @media (max-width: 330px) {
    .container-personalCabinet, .container-adminPanel {
      left: 0;
      min-width: 290px;
    }
  }
}


.container-personalCabinet {
  overflow-x: visible;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border: 1px solid #d5d5d5;
  }

  &::-webkit-scrollbar-track {
    border-radius: 2px;
    background: #eeeeee;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #b0b0b0;
  }
}

.title-personalCabinet, .title-adminPanel {
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 32px !important;
  color: #3E444A !important;
}

.label-personalCabinet, .label-adminPanel{
  display: block;
  position: relative;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #68717B;
}

.input-personalCabinet, .input-adminPanel{
  font-family: Raleway, sans-serif;
  width: 100%;
  border: 2px solid #C0D4E3;
  font-weight: 400;
  font-size: 14px;
  border-radius: 12px;
  padding: 0 40px 0 20px;
  height: 40px;
  cursor: pointer;
  color: #3E444A;
  line-height: 24px;

  &:focus {
    border-color: #0090D1;
  }

  &::placeholder {
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.25);
  }

}


.btnEdit-personalCabinet{
  display: flex;
  grid-gap: 10px;
  justify-content: center;
  align-items: center;
  width: 158px;
  height: 40px;
  background: #0090D1;
  border-radius: 8px;
  cursor: pointer;
  span{
      color: #FFFFFF;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
  }
}

.box-line {
  display: flex;
  align-items: center;
  grid-gap: 16px;
  h3 {
    white-space: nowrap;
    color: #3E444A;
    font-weight: 500;
    font-size: 16px;
  }

  div {
    width: 100%;
    background-color: #ECF2F7;
    height: 2px;
  }
}

.errorTextValidation {
  height: 15px;
  color: #FF6960;
  font-size: 12px;
  font-weight: 600;
}

.errorValidationInput {
  border: 1px solid #FF6960;
}

@media(max-width: 1330px) {
  .container {
    padding: 0 5%;
    margin: 0;
  }
}

@media(max-width: 700px) {
  .title-personalCabinet {
    font-size: 18px;
  }
  .box-line{
    h3{
      font-size: 14px;
    }
  }
  .btnEdit-personalCabinet{
    width: 120px;
    height: 35px;
    grid-gap: 7px;
    span{
        font-size: 11px;
    }
  }
}
