.label {

  >div {
    display: flex;
    justify-content: space-between;

    p:last-child {
      color: #0090D1;
      cursor: pointer;
    }
  }
}