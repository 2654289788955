.distance {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  & .distanceTitle {
    width: 20%;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #3E444A;
  }
}

.distanceNameInputContainer {
  margin-bottom: 10px;
}

.distanceNameLabel {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #68717B;

  & .distanceNameError {
    color: #FF6960;
    font-weight: 500;
    font-size: 13px;
  }

  &:hover {
    cursor: pointer;
  }
}

.distanceNameInput {
  font-family: "Raleway", sans-serif;
  width: 100%;
  border: 2px solid #C0D4E3;
  background: #fff;
  border-radius: 12px;
  padding: 8px 0 8px 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #3E444A;
  margin-top: 5px;

  &:focus {
    border: 2px solid rgba(0, 144, 209, 1);
    box-shadow: none;
  }
}

.distanceInsuranceLabel {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #68717B;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
}

.participationSumTitle {
  color: #68717B;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 10px;
}

.participationCostContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #C0D4E3;
  border-radius: 8px;
  padding: 8px 12px;
  margin-bottom: 15px;

  & .participationCostTitle {
    color: #3E444A;
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 7px;
  }

  & .participationDateContainer {
    display: flex;
    align-items: center;
  }

  & .participationBeforeDate,
  .participationUntilDate,
  .participationDateDivider,
  .participationDateCalendar {
    font-weight: 400;
    font-size: 16px;
    color: #68717B;
    line-height: 18px;
  }

  .participationBeforeDate {
    margin-left: 6px;
  }

  .participationDateDivider {
    margin: 0 4px;
  }

  & .participationEditButton,
  .participationDeleteButton {
    min-height: 0;
    min-width: 0;
    padding: 8px;

    & .participationEditImg,
    .participationDeleteImg {
      width: 20px;
      height: 20px;
    }
  }
}

.periodsError,
.startNumbersRangeError {
  text-align: center;
  margin: 20px 0 5px 5px;
  color: #FF6960;
}

.maleAgeGroupErrorTitle,
.femaleAgeGroupErrorTitle,
.startNumbersRangeErrorTitle {
  text-align: center;
  font-family: Raleway, sans-serif;
  font-size: 16px;
  color: #FF6960;
  margin-bottom: 5px;
}

.maleAgeGroupErrorContainer,
.femaleAgeGroupErrorContainer,
.startNumbersRangeErrorContainer {
  text-align: center;
  font-family: Raleway, sans-serif;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
  color: #68717B;

  & .maleAgeGroupInner,
  .femaleAgeGroupInner,
  .startNumbersRangeInner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    & .maleAgeGroupInnerDistanceName,
    .femaleAgeGroupInnerDistanceName,
    .startNumbersRangeInnerName {
      margin-right: 5px;
      font-weight: 500;
      font-size: 15px;
    }

    &>p {
      font-size: 14px;

      &>span {
        display: inline-block;
        font-size: 16px;
      }
    }
  }
}


@media screen and (max-width: 370px) {
  .distance {
    & .distanceTitle {
      width: 20%;
      font-size: 14px;
    }
  }
}