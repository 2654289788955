.drawerNavItemsContainer {
  & li {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & a {
      width: 100%;
      padding: 15px 0;
      color: #3E444A;
      font-size: 16px;
      font-weight: 500;
      margin-left: 7px;
      font-family: Raleway, sans-serif;
    }

    &:hover a {
      color: #0090D1;
    }

    &:hover svg path {
      stroke: #0090D1;
    }

    &:hover {
      background: #F0F8FF;
    }

    & svg {
      width: 23px;
      height: 23px;
      margin-left: 23px;
    }
  }
}
