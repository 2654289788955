.marathonResultsTop {
  font-family: 'Raleway', serif;
  max-width: 1300px;
  margin: 24px auto 0 auto;
  display: flex;
  justify-content: space-between;

  & .marathonResultsTopMainTitle {
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
    color: #3E444A;
    padding: 0 10px;
  }
}

.marathonResultsContainer {
  font-family: 'Raleway', serif;
  max-width: 1300px;
  margin: 24px auto 0 auto;
  padding: 20px;
  background: #fff;
  border-radius: 24px;

  & .marathonResultsSubTitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 40px;
    color: #3E444A;

    & .marathonResultsCount {
      opacity: 0.5;
    }
  }
}

.marathonResultsInnerTop {
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 35px;
}

.participantsFilterContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

;

.participantInputContainer {
  display: inline-block;
  width: 100%;
  position: relative;

  & .participantInput {
    width: 100%;
    border: 2px solid #C0D4E3;
    padding: 8px 10px 8px 12px;
    background: #fff;
    border-radius: 12px;
    font-size: 14px;
    line-height: 24px;
    font-family: Raleway, serif;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.70);

    cursor: pointer;

    &:focus {
      border-color: #0090D1;
    }

    &::placeholder {
      font-size: 14px;
      line-height: 24px;
      font-family: Raleway, serif;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
    }
  }

  & .participantSearchIcon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  @media (max-width: 415px) {
    & .participantSearchIcon {
      display: none;
    }

    & .participantInput {
      &::placeholder {
        font-size: 10px;
      }
    }
  }
}

.resetSelectedItem {
  font-family: Raleway, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #0090D1;
  background: transparent;
  cursor: pointer;
}

;

.selectTitle {
  display: inline-block;
  width: 100px;
  font-family: Raleway, serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #68717B;
}

.noRecords {
  color: #3E444A;
  font-size: 20px;
  font-family: Raleway, 'serif';
  text-transform: none;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
  margin: 15px 0;
}

.filterDistance,
.genderFilter,
.categoryFilter {
  flex: 1;
  margin-right: 10px;
}

@media screen and (max-width: 1100px) {

  .filterDistance,
  .genderFilter,
  .categoryFilter {
    width: 25%;
    margin-right: 10px;
  }
}

@media screen and (max-width: 768px) {
  .participantsFilterContainer {
    display: flex;
    flex-direction: column;

    &>button {
      margin: 15px auto 0 0;
    }
  }

  ;

  .filterDistance,
  .genderFilter,
  .categoryFilter {
    width: 47%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 500px) {

  .filterDistance,
  .genderFilter,
  .categoryFilter {
    width: 100%;
    margin-bottom: 20px;
  }
}
