.userDropDownMenuLink {
  color: #3E444A;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;

  &:hover {
    color: #0090D1;
  }

  &:hover svg path {
    stroke: #0090D1;
  }

  & .userDropDownMenuLabel {
    margin-left: 10px;
  }
}

.userDropDownMenuLinkActive {
  color: #0090D1;
  font-weight: 500;
  font-size: 14px;

  & svg path {
    stroke: #0090D1;
  }

  & .userDropDownMenuLabel {
    margin-left: 10px;
  }
}
