.textEditor-toolbar{
    border-radius: 10px 10px 0 0;
    border: 0.5px solid #C0D4E3;
    height: 52px;
    padding: 0;
    margin: 4px 0 0 0;
}

.textEditor-h2{
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;

}

.textEditor-h2 > div{
  border-radius: 10px 0 0 0;
}

.textEditor-list img{
  width: 20px;
  height: 20px;
}

.textEditor-toolbar > div > div{
    padding: 13px 10px;
    border: 0.5px solid #C0D4E3;
    height: 100%;
    margin: 0;

}

.rdw-link-modal{

  height: 205px !important;

}

.textEditor-text{

  height: 300px;

  padding: 12px;

  border: 0.5px solid #C0D4E3;

  border-radius: 0px 0px 10px 10px;

}
@media (max-width: 536px){
  .textEditor-text{
    padding: 60px 12px;
  }
}

.textEditor-text::-webkit-scrollbar{

  display: none;

}

.public-DraftStyleDefault-block{

  margin: 0;

  font-weight: 500;

  line-height: 160%;

  color: #3E444A;

}
